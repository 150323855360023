import { StaffItem, UserLocal } from './beans';
import { initStaffItem, initUserLocal } from './constant';

/**
 * 本地存储localstorage相关
 */

//通用本地取
export const getLocalItem = (key: string, initKey:any) => {
  return JSON.parse(localStorage.getItem(key) || JSON.stringify(initKey))
}

//通用本地存
export const setLocalItem = (key: string, keyValue: any) => {
  localStorage.setItem(key, JSON.stringify(keyValue))
}

//通用获取用户指定信息
export const getUserInfoKey = (key:string) => {
  const localUser:any = getLocalItem('userLocal', initStaffItem)
  return localUser[key]
}

//获取用户本地信息中的mobile
export const getUserMobile = () => {
  const localUser:StaffItem = getLocalItem('userLocal', initStaffItem)
  return localUser.mobile
}
//获取用户本地信息中的用户名
export const getUserName = () => {
  const localUser:StaffItem = getLocalItem('userLocal', initStaffItem)
  return localUser.name
}

//获取用户本地信息中的password
export const getUserPasswd = () => {
  const localUser:StaffItem = getLocalItem('userLocal', initStaffItem)
  return localUser.password
}
//获取用户本地信息中的id
export const localGetUserId = () => {
  const localUser:StaffItem = getLocalItem('userLocal', initStaffItem)
  return localUser.id
}

////获取用户本地信息中的token
export const getUserToken:any = () => {
  const localLocal:StaffItem = getLocalItem('userLocal', initStaffItem)
  return localLocal.token
}
//获取用户本地自动登陆状态
export const localGetUserAuto = () => {
  const localUser:StaffItem = getLocalItem('userLocal', initStaffItem)
  return localUser.autoLogin
}

////获取用户本地信息中的userLocal
export const getUserLocal = () => {
  const localLocal:StaffItem = getLocalItem('userLocal', initStaffItem)
  return localLocal
}