import React, {lazy,Suspense} from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
// import AccountBalance from '../pages/AccountBalance'
// import AccountBook from '../pages/AccountBook'
// import AffixDetail from '../pages/AffixDetail'
// import ApprovalLimits from '../pages/ApprovalLimits'
// import ApprovalProcess from '../pages/ApprovalProcess'
// import BankDaily from '../pages/BankDaily'
// import BillStatistics from '../pages/BillStatistics'
// import BillSummary from '../pages/BillSummary'
// import BranchSetting from '../pages/BranchSetting'
// import CashDaily from '../pages/CashDaily'
// import ChargedataImport from '../pages/ChargedataImport'
// import ChargeFinance from '../pages/ChargeFinance'
// import ChargeRelations from '../pages/ChargeRelations'
// import ChargeRule from '../pages/ChargeRule'
// import ComPayment from '../pages/ComPayment'
// import CustBalance from '../pages/CustBalance'
// import CustBillApproval from '../pages/CustBillApproval'
// import CustBillflow from '../pages/CustBillflow'
// import CustCashBill from '../pages/CustCashBill'
// import CustMonthClear from '../pages/CustMonthClear'
// import CustomerArchives from '../pages/CustomerArchives'
// import CustPayRepo from '../pages/CustPayRepo'
// import CustPrePay from '../pages/CustPrePay'
// import Dailyflow from '../pages/Dailyflow'
// import DeliveryCollectPay from '../pages/DeliveryCollectPay'
// import DetailBook from '../pages/DetailBook'
// import DispatchFee from '../pages/DispatchFee'
// import ExpressAccountingSubject from '../pages/ExpressAccountingSubject'
// import FeetypeManage from '../pages/FeetypeManage'
// import FeeTypeSetting from '../pages/FeeTypeSetting'
// import FinanceAssets from '../pages/FinanceAssets'
// import FinanceInout from '../pages/FinanceInout'
// import FineBill from '../pages/FineBill'
// import FirmAccountCheck from '../pages/FirmAccountCheck'
// import FirmAccountSetting from '../pages/FirmAccountSetting'
// import GpBalanceSheet from '../pages/GpBalanceSheet'
// import GpCashflow from '../pages/GpCashflow'
// import GpMonthProfit from '../pages/GpMonthProfit'
// import GpRatesetting from '../pages/GpRatesetting'
// import Guide from '../pages/Guide'
// import HQData from '../pages/HQData'
// import HqOrigin from '../pages/HqOrigin'
// import HQStatistics from '../pages/HQStatistics'
// import IndexPage from '../pages/IndexPage'
// import InitialAccouts from '../pages/InitialAccouts'
// import InvalidDetail from '../pages/InvalidDetail'
// import IODataRecord from '../pages/IODataRecord'
// import Login from '../pages/Login'
// import Main from '../pages/Main'
// import MaterialClaim from '../pages/MaterialClaim'
// import MaterialClaimlog from '../pages/MaterialClaimlog'
// import MaterialCost from '../pages/MaterialCost'
// import MaterialDailyCheck from '../pages/MaterialDailyCheck'
// import MaterialMonthStat from '../pages/MaterialMonthStat'
// import MaterialPrice from '../pages/MaterialPrice'
// import MaterialPurch from '../pages/MaterialPurch'
// import MenuSetting from '../pages/MenuSetting'
// import MonthClear from '../pages/MonthClear'
// import OtherFee from '../pages/OtherFee'
// import PlantBalance from '../pages/PlantBalance'
// import PledgeLoan from '../pages/PledgeLoan'
// import ProductMonthProfit from '../pages/ProductMonthProfit'
// import ProductSingleAnal from '../pages/ProductSingleAnal'
// import ProductYearAnal from '../pages/ProductYearAnal'
// import ProofCollection from '../pages/ProofCollection'
// import Recharge from '../pages/Recharge'
// import RecordVoucher from '../pages/RecordVoucher'
// import Reimbursement from '../pages/Reimbursement'
// import RelevantUnits from '../pages/RelevantUnits'
// import SendCollect from '../pages/SendCollect'
// import SiteApply from '../pages/SiteApply'
// import SiteManagement from '../pages/SiteManagement'
// import SiteSetting from '../pages/SiteSetting'
// import StaffManagement from '../pages/StaffManagement'
// import StockIn from '../pages/StockIn'
// import SubjectManage from '../pages/SubjectManage'
// import TitleSetting from '../pages/TitleSetting'
// import TransferFee from '../pages/TransferFee'
// import UserBalance from '../pages/UserBalance'
// import UserbalanceDetail from '../pages/UserbalanceDetail'
// import UserPrivilege from '../pages/UserPrivilege'
import { getUserToken } from '../utils/localstorage'
import store, { setUser } from "../utils/store";

const AccountBalance = lazy(()=>import('../pages/AccountBalance'))
const AccountBook = lazy(()=>import('../pages/AccountBook'))
const AffixDetail = lazy(()=>import('../pages/AffixDetail'))
const ApprovalLimits = lazy(()=>import('../pages/ApprovalLimits'))
const ApprovalProcess = lazy(()=>import('../pages/ApprovalProcess'))
const BankDaily = lazy(()=>import('../pages/BankDaily'))
const BillStatistics = lazy(()=>import('../pages/BillStatistics'))
const BillSummary = lazy(()=>import('../pages/BillSummary'))
const BranchSetting = lazy(()=>import('../pages/BranchSetting'))
const CashDaily = lazy(()=>import('../pages/CashDaily'))
const ChargedataImport = lazy(()=>import('../pages/ChargedataImport'))
const ChargeFinance = lazy(()=>import('../pages/ChargeFinance'))
const ChargeRelations = lazy(()=>import('../pages/ChargeRelations'))
const ChargeRule = lazy(()=>import('../pages/ChargeRule'))
const ComPayment = lazy(()=>import('../pages/ComPayment'))
const CustBalance = lazy(()=>import('../pages/CustBalance'))
const CustBillApproval = lazy(()=>import('../pages/CustBillApproval'))
const CustBillflow = lazy(()=>import('../pages/CustBillflow'))
const CustCashBill = lazy(()=>import('../pages/CustCashBill'))
const CustMonthClear = lazy(()=>import('../pages/CustMonthClear'))
const CustomerArchives = lazy(()=>import('../pages/CustomerArchives'))
const CustPayRepo  = lazy(()=>import('../pages/CustPayRepo'))
const CustPrePay = lazy(()=>import('../pages/CustPrePay'))
const Dailyflow = lazy(()=>import('../pages/Dailyflow'))
const DeliveryCollectPay = lazy(()=>import('../pages/DeliveryCollectPay'))
const DetailBook = lazy(()=>import('../pages/DetailBook'))
const DispatchFee = lazy(()=>import('../pages/DispatchFee'))
const ExpressAccountingSubject = lazy(()=>import('../pages/ExpressAccountingSubject'))
const FeetypeManage  = lazy(()=>import('../pages/FeetypeManage'))
const FeeTypeSetting = lazy(()=>import('../pages/FeeTypeSetting'))
const FinanceAssets = lazy(()=>import('../pages/FinanceAssets'))
const FinanceInout = lazy(()=>import('../pages/FinanceInout'))
const FineBill = lazy(()=>import('../pages/FineBill'))
const FirmAccountCheck = lazy(()=>import('../pages/FirmAccountCheck'))
const FirmAccountSetting = lazy(()=>import('../pages/FirmAccountSetting'))
const GpBalanceSheet = lazy(()=>import('../pages/GpBalanceSheet'))
const GpCashflow = lazy(()=>import('../pages/GpCashflow'))
const GpMonthProfit = lazy(()=>import('../pages/GpMonthProfit'))
const GpRatesetting = lazy(()=>import('../pages/GpRatesetting'))
const Guide = lazy(()=>import('../pages/Guide'))
const HQData = lazy(()=>import('../pages/HQData'))
const HqOrigin = lazy(()=>import('../pages/HqOrigin'))
const HQStatistics = lazy(()=>import('../pages/HQStatistics'))
const IndexPage = lazy(()=>import('../pages/IndexPage'))
const InitialAccouts = lazy(()=>import('../pages/InitialAccouts'))
const InvalidDetail = lazy(()=>import('../pages/InvalidDetail'))
const IODataRecord = lazy(()=>import('../pages/IODataRecord'))
const Login = lazy(()=>import('../pages/Login'))
const Main = lazy(()=>import('../pages/Main'))
const MaterialClaim = lazy(()=>import('../pages/MaterialClaim'))
const MaterialClaimlog = lazy(()=>import('../pages/MaterialClaimlog'))
const MaterialCost = lazy(()=>import('../pages/MaterialCost'))
const MaterialDailyCheck = lazy(()=>import('../pages/MaterialDailyCheck'))
const MaterialMonthStat = lazy(()=>import('../pages/MaterialMonthStat'))
const MaterialPrice = lazy(()=>import('../pages/MaterialPrice'))
const MaterialPurch = lazy(()=>import('../pages/MaterialPurch'))
const MenuSetting = lazy(()=>import('../pages/MenuSetting'))
const MonthClear = lazy(()=>import('../pages/MonthClear'))
const OtherFee = lazy(()=>import('../pages/OtherFee'))
const PlantBalance = lazy(()=>import('../pages/PlantBalance'))
const PledgeLoan = lazy(()=>import('../pages/PledgeLoan'))
const ProductMonthProfit = lazy(()=>import('../pages/ProductMonthProfit'))
const ProductSingleAnal = lazy(()=>import('../pages/ProductSingleAnal'))
const ProductYearAnal = lazy(()=>import('../pages/ProductYearAnal'))
const ProofCollection = lazy(()=>import('../pages/ProofCollection'))
const Recharge = lazy(()=>import('../pages/Recharge'))
const RecordVoucher = lazy(()=>import('../pages/RecordVoucher'))
const Reimbursement = lazy(()=>import('../pages/Reimbursement'))
const RelevantUnits = lazy(()=>import('../pages/RelevantUnits'))
const SendCollect = lazy(()=>import('../pages/SendCollect'))
const SiteApply = lazy(()=>import('../pages/SiteApply'))
const SiteManagement = lazy(()=>import('../pages/SiteManagement'))
const SiteSetting = lazy(()=>import('../pages/SiteSetting'))
const StaffManagement = lazy(()=>import('../pages/StaffManagement'))
const StockIn = lazy(()=>import('../pages/StockIn'))
const SubjectManage = lazy(()=>import('../pages/SubjectManage'))
const TitleSetting = lazy(()=>import('../pages/TitleSetting'))
const TransferFee = lazy(()=>import('../pages/TransferFee'))
const UserBalance = lazy(()=>import('../pages/UserBalance'))
const UserbalanceDetail = lazy(()=>import('../pages/UserbalanceDetail'))
const UserPrivilege = lazy(()=>import('../pages/UserPrivilege'))

export default function NavRoutes() {
  return (
    <Suspense fallback={<h1></h1>}>
      <Routes>
        <Route path='/' element={<Navigate to='/login'/>}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/indexpage' element={<IndexPage />}>
          <Route path='' element={<Main />}></Route>
          <Route path='main' element={<Main />}></Route>
          <Route path='guide' element={<Guide />}></Route>
          <Route path='gpratesetting' element={<GpRatesetting />}></Route>
          <Route path='staffmanagement' element={<StaffManagement />}></Route>
          <Route path='branchsetting' element={<BranchSetting />}></Route>
          <Route path='titlesetting' element={<TitleSetting />}></Route>
          <Route path='firmaccountsetting' element={<FirmAccountSetting />}></Route>
          <Route path='expressaccountingsubject' element={<ExpressAccountingSubject />}></Route>
          <Route path='feetypesetting' element={<FeeTypeSetting />}></Route>
          <Route path='initialaccounts' element={<InitialAccouts />}></Route>
          <Route path='customerarchives' element={<CustomerArchives />}></Route>
          <Route path='stockin' element={<StockIn />}></Route>
          <Route path='financeassets' element={<FinanceAssets />}></Route>
          <Route path='approvallimits' element={<ApprovalLimits />}></Route>
          <Route path='approvalprocess' element={<ApprovalProcess />}></Route>
          <Route path='relevantunits' element={<RelevantUnits />}></Route>
          <Route path='compayment' element={<ComPayment />}></Route>
          <Route path='plantbalance' element={<PlantBalance />}></Route>
          <Route path='billsummary' element={<BillSummary />}></Route>
          <Route path='billstatistics' element={<BillStatistics />}></Route>
          <Route path='reimbursement' element={<Reimbursement />}></Route>
          <Route path='deliverycollectpay' element={<DeliveryCollectPay />}></Route>
          <Route path='sendcollect' element={<SendCollect />}></Route>
          <Route path='recharge' element={<Recharge />}></Route>
          <Route path='userbalance' element={<UserBalance />}></Route>
          <Route path='userbalancedetail' element={<UserbalanceDetail />}></Route>
          <Route path='transferfee' element={<TransferFee />}></Route>
          <Route path='finebill' element={<FineBill />}></Route>
          <Route path='dispatchfee' element={<DispatchFee />}></Route>
          <Route path='otherfee' element={<OtherFee />}></Route>
          <Route path='pledgeloan' element={<PledgeLoan />}></Route>
          <Route path='invaliddetail' element={<InvalidDetail />}></Route>
          <Route path='hqdata' element={<HQData />}></Route>
          <Route path='hqorigin' element={<HqOrigin />}></Route>
          <Route path='hqstatistics' element={<HQStatistics />}></Route>
          <Route path='materialpurch' element={<MaterialPurch />}></Route>
          <Route path='materialclaim' element={<MaterialClaim />}></Route>
          <Route path='materialclaimlog' element={<MaterialClaimlog />}></Route>
          <Route path='materialprice' element={<MaterialPrice />}></Route>
          <Route path='materialdailycheck' element={<MaterialDailyCheck />}></Route>
          <Route path='materialcost' element={<MaterialCost />}></Route>
          <Route path='materialmonthstat' element={<MaterialMonthStat />}></Route>
          <Route path='chargerule' element={<ChargeRule />}></Route>
          <Route path='chargerelations' element={<ChargeRelations />}></Route>
          <Route path='chargedataimport' element={<ChargedataImport />}></Route>
          <Route path='chargefinance' element={<ChargeFinance />}></Route>
          <Route path='financeassets' element={<FinanceAssets />}></Route>
          <Route path='financeinout' element={<FinanceInout />}></Route>
          <Route path='custcashbill' element={<CustCashBill />}></Route>
          <Route path='custmonthclear' element={<CustMonthClear />}></Route>
          <Route path='custbalance' element={<CustBalance />}></Route>
          <Route path='custprepay' element={<CustPrePay />}></Route>
          <Route path='custbillapproval' element={<CustBillApproval />}></Route>
          <Route path='custbillflow' element={<CustBillflow />}></Route>
          <Route path='custpayrepo' element={<CustPayRepo />}></Route>
          <Route path='recordvoucher' element={<RecordVoucher />}></Route>
          <Route path='accountbook' element={<AccountBook />}></Route>
          <Route path='accountbalance' element={<AccountBalance />}></Route>
          <Route path='proofcollection' element={<ProofCollection />}></Route>
          <Route path='bankdaily' element={<BankDaily />}></Route>
          <Route path='cashdaily' element={<CashDaily />}></Route>
          <Route path='affixdetail' element={<AffixDetail />}></Route>
          <Route path='detailbook' element={<DetailBook />}></Route>
          <Route path='dailyflow' element={<Dailyflow />}></Route>
          <Route path='firmaccountcheck' element={<FirmAccountCheck />}></Route>
          <Route path='monthclear' element={<MonthClear />}></Route>
          <Route path='iodatarecord' element={<IODataRecord />}></Route>
          <Route path='productmonthprofit' element={<ProductMonthProfit />}></Route>
          <Route path='gpmonthprofit' element={<GpMonthProfit />}></Route>
          <Route path='productsingleanal' element={<ProductSingleAnal />}></Route>
          <Route path='productyearanal' element={<ProductYearAnal />}></Route>
          <Route path='gpbalancesheet' element={<GpBalanceSheet />}></Route>
          <Route path='gpcashflow' element={<GpCashflow />}></Route>
          <Route path='userprivilege' element={<UserPrivilege />}></Route>
          <Route path='menusetting' element={<MenuSetting />}></Route>
          <Route path='sitesetting' element={<SiteSetting />}></Route>
          <Route path='siteapply' element={<SiteApply />}></Route>
          <Route path='sitemanagement' element={<SiteManagement />}></Route>
          <Route path='subjectmanage' element={<SubjectManage />}></Route>
          <Route path='feetypemanage' element={<FeetypeManage />}></Route>
        </Route>
      </Routes>
    </Suspense>
  )
}
