import { createSlice, configureStore } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    userInfor: { isLogin: true },
    loadingFunc:null as any,
    auth: true
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.userInfor = payload
    },
    setLoadFunc: (state, { payload }) => {
      state.loadingFunc = payload
    },
    setAuth:(state, { payload }) => {
      state.auth = payload
    }
  }
})
const navigatorSlice = createSlice({
  name: 'navigatorSlice',
  initialState: { 
    navigator: null as any
  },
  reducers: {
    setNavigator:(state, { payload }) => {
      state.navigator = payload
    }
  }
})

const store = configureStore({
  reducer: {
    userStore: userSlice.reducer,
    navigatorStore: navigatorSlice.reducer
  }
})

export const { setUser, setLoadFunc, setAuth } = userSlice.actions
export const { setNavigator } = navigatorSlice.actions

export default store